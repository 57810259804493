import React, { useRef, useEffect, useState } from 'react';
import { pathImages } from '../../../../pathImages';
import s from "./videoPlayer.module.css"

const VideoPlayer = ({video}) => {
  // const videoRef = useRef(null);
  // const [userInteracted, setUserInteracted] = useState(false);

  // const handleVideoInteract = () => {
  //   if (!userInteracted) {
  //     setUserInteracted(true);
  //     videoRef.current.play();
  //   }
  // };

  
  // // Configuramos el Intersection Observer
  // useEffect(() => {
  //   const options = {
  //     root: null, // Observar en el viewport
  //     rootMargin: '0px',
  //     threshold: 0.5, // Porcentaje del elemento visible para considerarlo en la vista
  //   };

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         // El video está en la vista, reproducirlo
  //         // videoRef.current.play();
  //         handleVideoInteract();
  //       } else {
  //         // El video ya no está en la vista, pausarlo
  //         videoRef.current.pause();
  //       }
  //     });
  //   }, options);

  //   if (videoRef.current) {
  //     observer.observe(videoRef.current);
  //   }

  //   // Limpieza del observer cuando el componente se desmonte
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [userInteracted]);

  return (
    <div className={s.videoCont}>
      {/* <video ref={videoRef} controls> */}
      <video controls>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
