import s from "./modelos.module.css"
import { HiChevronRight } from "react-icons/hi";
import BFBDtrenta_cinco from "../../assets/modelos/Imagen 422.png"
import BFBDcincuenta from "../../assets/modelos/Imagen 422.png"
import BFBDsetenta from "../../assets/modelos/Imagen 456.png"
import BFBDciencto_diez from "../../assets/modelos/Imagen 457.png"
import BFBDciencto_sesenta_cinco from "../../assets/modelos/Imagen 458.png"
import BFBDdoscientos_veinte from "../../assets/modelos/Imagen 459.png"
import BFBDtres_treinta from "../../assets/modelos/Imagen 460.png"
import { pathImages } from "../../pathImages";
import Card from "./Card/Card";
import { infoCards } from "./info";
import { useEffect, useState } from "react";

export default function Modelos() {
    const [motor, setMotor] = useState({ ok: false, index: "false" })
    // console.log(infoCards[motor.index])

    // const isMobile = () => window.innerWidth <= 768;
    const isMobile = window.innerWidth <= 768;

    const handleMotor = (index) => {
        setMotor({
            ok: true,
            index
        })
        // console.log(infoCards[index]?.name)
        // console.log(infoCards[motor.index]?.name)
        // scrollToSection("Card")
    }

    const scrollToSection = (id, offset = 0) => {
        const element = document.getElementById(id);

        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollPosition = rect.top + window.scrollY - offset;
            // console.log(scrollPosition, "position")
            //   element.scrollIntoView({
            //     top: scrollPosition,
            //     behavior: 'smooth',
            //   });

            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        // console.log(isMobile, "ismobile")
        if (isMobile) {
            scrollToSection("Card", 100)
        } else {
            scrollToSection("CardWeb", 200)
        }
    }, [motor])

    return (
        <>
            <div id="Modelos" className={s.section}>
                <h3>MODELOS</h3>
                <div className={s.modelosCont}>
                    <div className={s.modeloCard} onClick={() => handleMotor(0)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDtrenta_cinco} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 0 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 35</span>
                            <HiChevronRight color={motor?.index == 0 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>
                    <div className={s.modeloCard} onClick={() => handleMotor(1)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDcincuenta} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 1 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 50</span>
                            <HiChevronRight color={motor?.index == 1 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>
                    <div className={s.modeloCard} onClick={() => handleMotor(2)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDsetenta} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 2 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 70</span>
                            <HiChevronRight color={motor?.index == 2 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>



                    <div className={s.modeloCard} onClick={() => handleMotor(3)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDsetenta} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 3 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 90</span>
                            <HiChevronRight color={motor?.index == 3 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>

                    <div className={s.modeloCard} onClick={() => handleMotor(4)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDciencto_diez} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 4 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 110</span>
                            <HiChevronRight color={motor?.index == 4 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>

                    <div className={s.modeloCard} onClick={() => handleMotor(5)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDciencto_sesenta_cinco} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 5 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 150</span>
                            <HiChevronRight color={motor?.index == 5 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>


                    <div className={s.modeloCard} onClick={() => handleMotor(6)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDciencto_sesenta_cinco} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 6 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 165</span>
                            <HiChevronRight color={motor?.index == 6 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>
                    <div className={s.modeloCard} onClick={() => handleMotor(7)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDdoscientos_veinte} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 7 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 220</span>
                            <HiChevronRight color={motor?.index == 7 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>
                    <div className={s.modeloCard} onClick={() => handleMotor(8)}>
                        <div className={s.imgCont}><img src={pathImages + BFBDdoscientos_veinte} alt="" /></div>
                        <div className={s.modeloInfo} style={motor?.index == 8 ? { backgroundColor: "#67A23A", color: "#FFFFFF" } : {}}>
                            <span>BF BD 330</span>
                            <HiChevronRight color={motor?.index == 8 ? "#FFFFFF" : "#67A23A"} />
                        </div>
                    </div>
                </div>
            </div>
            {
                motor?.ok && <Card info={infoCards[motor.index]} scrollToSection={scrollToSection} setMotor={setMotor} />
            }
        </>
    )
}