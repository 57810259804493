import Descripcion from "../Descripcion/Descripcion";
import Header from "../Header/Header";
import Modelos from "../Modelos/Modelos";
import Footer from "../footer/Footer";
import Navbar from "../navbar/nabvarWeb/Navbar";
import NavMobile from "../navbar/navbarMobile/NavbarMobile";
import s from "./Landing.module.css"
import { useEffect, useState } from 'react';


export default function Landing(){
    const [isMobile, setIsMobile] = useState(false);
    const [isInIframe, setIsInIframe] = useState(false);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Establece el límite de ancho para considerarlo "mobile"
    };
    
    const currentUrl = window.location.href;
    // const urlBaifa = "http://localhost:3000/"
    const urlBaifa = "https://www.baifa.com.ar/"
    
    useEffect(() => {
      if (window.self !== window.top) {
        setIsInIframe(true);
    }
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    console.log(isInIframe)
    return(
        <div className={s.section}>
            {
                isMobile ? <NavMobile isMobile={isMobile}/> : <Navbar isMobile={isMobile}/>
            }
            {/* <NavMobile/> */}
            <Header/>
            <Modelos/>
            <Descripcion/>
            {/* {
              urlBaifa === currentUrl && <Footer color={"#67A23A"}/>
            } */}
              {!isInIframe && <Footer color={"#67A23A"}/>}
            
        </div>
    )
}