import s from "./card.module.css"
import BFBDtrenta_cinco from "../../../assets/modelos/Imagen 422.png"
import { pathImages } from "../../../pathImages"
import { HiChevronRight, HiChevronUp } from "react-icons/hi";
import test from "../../../assets/modelos/web/Imagen 425.png"


export default function Card({ info, setMotor, scrollToSection }) {
    let { name, standBy, prime, motor, alternador, cilindros, cilindrada, rpm, potenciaMax, tensionNominal, fases, frecNominal, factorPotencia, peso, consumo, largo, pdf, imagenWeb } = info

    const handleDownload = () => {
        window.open(pathImages + pdf, '_blank');
        // -----------------------------------
        // const fileName = name + '.pdf'; // Reemplaza esto con el nombre que desees para el archivo

        // // Crear un enlace temporal para la descarga
        // const link = document.createElement('a');
        // link.href = pathImages + pdf;
        // link.target = '_blank'; // Abrir el enlace en una nueva pestaña (opcional)
        // link.download = fileName;
        // link.click();
    };


    return (
        <>
            {/* ------------------------------MOBILE-------------------------------------- */}
            <div className={s.section} id={"Card"}>
                <h3 >{name}</h3>
                <div className={s.imgCont}><img src={pathImages + imagenWeb} alt="modelo" /></div>
                <div className={s.modeloData}>
                    <div className={s.dataRow}>
                        <span className={s.dataTitle}>STAND BY</span>
                        <span className={s.dataValue}>{standBy}</span>
                    </div>
                    <div className={s.dataRow}>
                        <span className={s.dataTitle}>PRIME</span>
                        <span className={s.dataValue}>{prime}</span>
                    </div>
                    <div className={s.dataRow}>
                        <span className={s.dataTitle}>MOTOR</span>
                        <span className={s.dataValue}>{motor}</span>
                    </div>
                    <div className={s.dataRow}>
                        <span className={s.dataTitle}>ALTERNADOR</span>
                        <span className={s.dataValue}>{alternador}</span>
                    </div>
                </div>
                <div className={s.sectionCracteristicas}>
                    <div className={s.titleCont}>
                        <h3>características principales</h3>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Cilindros</span>
                            <span>{cilindros}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Cilindrada (C.C.)</span>
                            <span>{cilindrada}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>R.P.M.</span>
                            <span>{rpm}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Potencia máxima (Kva)</span>
                            <span>{potenciaMax}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Tensión nominal (Volts)</span>
                            <span>{tensionNominal}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Fases</span>
                            <span>{fases}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Frecuencia nominal CA (Hz)</span>
                            <span>{frecNominal}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Factor de potencia</span>
                            <span>{factorPotencia}</span>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Peso (kg)</span>
                            <span>{peso}</span>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Consumo m  /H a 100% carga</span>
                            <span>{consumo}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowInfoCont}>
                            <span>Largo / Ancho / Alto (cm)</span>
                            <span>{largo}</span>
                        </div>
                    </div>

                    <div className={s.descargarFichaContainer}>
                        <div className={s.descargar} onClick={() => handleDownload()}>
                            <span>DESCARGAR FICHA</span>
                            <HiChevronRight />
                        </div>
                        <div className={s.volver} onClick={() =>{ 
                            setMotor({ ok: false, index: "false" })
                            scrollToSection("Modelos")   
                        }
                        }>
                            <span>VOLVER</span>
                            <HiChevronUp />
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------WEB------------------------------ */}
            <div className={s.sectionWeb}>
                <div className={s.portada}>
                    <div className={s.portadaModeloDataCont}>
                        <h3 id="CardWeb">{name}</h3>
                        <div className={s.modeloData}>
                            <div className={s.dataRow}>
                                <span className={s.dataTitle}>STAND BY</span>
                                <span className={s.dataValue}>{standBy}</span>
                            </div>
                            <div className={s.dataRow}>
                                <span className={s.dataTitle}>PRIME</span>
                                <span className={s.dataValue}>{prime}</span>
                            </div>
                            <div className={s.dataRow}>
                                <span className={s.dataTitle}>MOTOR</span>
                                <span className={s.dataValue}>{motor}</span>
                            </div>
                            <div className={s.dataRow}>
                                <span className={s.dataTitle}>ALTERNADOR</span>
                                <span className={s.dataValue}>{alternador}</span>
                            </div>
                        </div>
                    </div>
                    <div className={s.imgCont}><img src={pathImages + imagenWeb} alt="modelo" /></div>
                </div>
                <div className={s.sectionCracteristicas}>
                    <div className={s.titleCont}>
                        <h3>características principales</h3>
                    </div>
                    <div className={s.dataCont}>
                        <div className={s.dataCol}>

                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Cilindros</span>
                                    <span>{cilindros}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Cilindrada (C.C.)</span>
                                    <span>{cilindrada}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>R.P.M.</span>
                                    <span>{rpm}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Potencia máxima (Kva)</span>
                                    <span>{potenciaMax}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Tensión nominal (Volts)</span>
                                    <span>{tensionNominal}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Fases</span>
                                    <span>{fases}</span>
                                </div>
                            </div>
                        </div>
                        <div className={s.dataCol}>

                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Frecuencia nominal CA (Hz)</span>
                                    <span>{frecNominal}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Factor de potencia</span>
                                    <span>{factorPotencia}</span>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Peso (kg)</span>
                                    <span>{peso}</span>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Consumo m  /H a 100% carga</span>
                                    <span>{consumo}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                    <span>Largo / Ancho / Alto (cm)</span>
                                    <span>{largo}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowInfoCont}>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={s.descargarFichaContainer}>
                        <div style={{ color: "#EFEFEF", width: "100%" }}>saaaaaaaaaaaaaaaaaaaas</div>
                        <div className={s.descargar} onClick={() => handleDownload()}>
                            <span>DESCARGAR FICHA</span>
                            <HiChevronRight />
                        </div>
                        <div className={s.volver} onClick={() => {
                            scrollToSection("Modelos")
                            setMotor({ ok: false, index: "false" })
                        }
                        }>
                            <span>VOLVER</span>
                            <HiChevronUp />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}