import bfBdTreinta_cinco from "../../assets/modelos/ficha 35.pdf"
import bfBdCincuenta from "../../assets/modelos/ficha 50.pdf"
import bfBdSetenta from "../../assets/modelos/ficha 70.pdf"
import bfBdNoventa from "../../assets/modelos/ficha 90.pdf"
import bfBdCiento_diez from "../../assets/modelos/ficha 110.pdf"
import bfBdCiento_cincuenta from "../../assets/modelos/ficha 150.pdf"
import bfBdCiento_sesenta_cinco from "../../assets/modelos/ficha 165.pdf"
import bfBdDoscientos_veinte from "../../assets/modelos/ficha 220.pdf"
import bfBdTrescientos_treinta from "../../assets/modelos/ficha 330.pdf"
import bfBdTrescientos_treintaWeb from "../../assets/modelos/web/Imagen 425.png"
 


//  ------------------IMG WEB----------------------------
import imagen_treinta_cinco_web from "../../assets/modelos/web/Imagen 422.png"
import imagen_cincuenta_web from "../../assets/modelos/web/Imagen 422.png"
import imagen_setenta_web from "../../assets/modelos/web/Imagen 446.png"
import imagen_ciento_diez_web from "../../assets/modelos/web/Imagen 426.png"
import imagen_ciento_sesenta_cinco_web from "../../assets/modelos/web/Imagen 426.png"
import imagen_doscientos_veinte_web from "../../assets/modelos/web/Imagen 448.png"
import imagen_trescientos_treinta_web from "../../assets/modelos/web/Imagen 448.png"
// ----------------------IMG MOBILE------------------------
import img_mobile_treinta_cinco from "../../assets/modelos/mobile/Imagen 422.png"

export const infoCards = [
    {
        name: "BF BD 35",
        standBy: "35 KVA",
        prime: "32 KVA",
        motor: "4M06G35/5",
        alternador: "BF1G",
        cilindros: "4",
        cilindrada: "2.3 L",
        rpm: "1500 ",
        potenciaMax: "35",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "1250 Kg",
        consumo: "7.6 L/H7.6 L/H7.6 L/H",
        largo: "2290x1110x1250 mm",
        pdf: bfBdTreinta_cinco,
        imagenWeb: imagen_treinta_cinco_web 
    },
    {
        name: "BF BD 50",
        standBy: "50 KVA",
        prime: "32 KVA",
        motor: "4M06G35/5",
        alternador: "BF1G",
        cilindros: "4",
        cilindrada: "2.3 L",
        rpm: "1500 ",
        potenciaMax: "50",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "1370 Kg",
        consumo: "10.1 L/H",
        largo: "2440x1110x1250 mm",
        pdf: bfBdCincuenta,
        imagenWeb: imagen_cincuenta_web 
    },
    {
        name: "BF BD 70",
        standBy: "68.8 KVA",
        prime: "62.5 KVA",
        motor: "4M10G70/5",
        alternador: "BF2C",
        cilindros: "4",
        cilindrada: "4.087 L",
        rpm: "1500 ",
        potenciaMax: "70",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "1500 Kg",
        consumo: "14.3  L/H",
        largo: "2440x1110x1250 mm",
        pdf: bfBdSetenta,
        imagenWeb: imagen_setenta_web 
    },

    {
        name: "BF BD 90",
        standBy: "90 KVA",
        prime: "80 KVA",
        motor: "4M10G88/5",
        alternador: "BF3D",
        cilindros: "4",
        cilindrada: "4.087 L",
        rpm: "1500 ",
        potenciaMax: "90",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "1140 Kg",
        consumo: "18.1  L/H",
        largo: "1890x700x1300 mm",
        pdf: bfBdNoventa,
        imagenWeb: imagen_setenta_web
    },

    {
        name: "BF BD 110",
        standBy: "110 KVA",
        prime: "100 KVA",
        motor: "4M10G110/5",
        alternador: "BF3A",
        cilindros: "4",
        cilindrada: "4.087 L",
        rpm: "1500 ",
        potenciaMax: "110",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "1740 Kg",
        consumo: "21.9  L/H",
        largo: "2810x1140x1830 mm",
        pdf: bfBdCiento_diez,
        imagenWeb: imagen_ciento_diez_web 
    },
    {
        name: "BF BD 150",
        standBy: "150  KVA",
        prime: "135 KVA",
        motor: "6M11G150/5",
        alternador: "BF3D",
        cilindros: "6",
        cilindrada: "6.75 L",
        rpm: "1500",
        potenciaMax: "150",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "1480 Kg",
        consumo: "28.7 L/H",
        largo: "2440x830x1410 mm",
        pdf: bfBdCiento_cincuenta,
        imagenWeb: imagen_ciento_sesenta_cinco_web 
    },
    {
        name: "BF BD 165",
        standBy: "165  KVA",
        prime: "150 KVA",
        motor: "4M06G35/5",
        alternador: "BF3D",
        cilindros: "6",
        cilindrada: "6.75 L",
        rpm: "1500",
        potenciaMax: "165",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "2320Kg",
        consumo: "31 L/H",
        largo: "3230x1170x1800 mm",
        pdf: bfBdCiento_sesenta_cinco,
        imagenWeb: imagen_ciento_sesenta_cinco_web 
    },
    {
        name: "BF BD 220",
        standBy: "220 KVA",
        prime: "200 KVA",
        motor: "6M16G220/5",
        alternador: "BF3F",
        cilindros: "6",
        cilindrada: "9.726 L",
        rpm: "1500",
        potenciaMax: "220",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "3100Kg",
        consumo: "42 L/H",
        largo: "3590x1310x2050 mm",
        pdf: bfBdDoscientos_veinte,
        imagenWeb: imagen_doscientos_veinte_web 
    },
    {
        name: "BF BD 330",
        standBy: "330 KVA",
        prime: "300 KVA",
        motor: "6M16G350/5",
        alternador: "BF4M",
        cilindros: "6",
        cilindrada: "9.726 L",
        rpm: "1500",
        potenciaMax: "330",
        tensionNominal: "380",
        fases: "3",
        frecNominal: "50",
        factorPotencia: "0.8",
        peso: "3950Kg",
        consumo: "65 L/H",
        largo: "4090x1460x2150 mm",
        pdf: bfBdTrescientos_treinta,
        imagenWeb: imagen_doscientos_veinte_web
    }
]