import whatsapp from "../../assets/footer/whatsapp.svg"
import { pathImages } from "../../pathImages";
import s from "./Footer.module.css"
import { BsCurrencyDollar, BsLinkedin, BsInstagram, BsYoutube } from "react-icons/bs"


export default function Footer({ color }) {
  return (
    <footer id={s.contacto}>
      <div className={s.footerContainer}>
        <div className={s.info}>
          <p className={s.text} onClick={(e) => window.open("https://www.navalmotor.com/contacto", '_blank')}>CONTACTO</p>
          {/* <p>Av. del Libertador 701, Vicente López, Buenos Aires</p> */}
          <p>ventas@baifa.com.ar</p>
          <p>Dr. Enrique Finochietto 1211, Buenos Aires</p>
          <div className={s.numero}>
            <div className={s.wspContainer}>
              <img src={pathImages + whatsapp} alt="contact" />
            </div>
            {/* <span>+541126661777</span> */}
            {/* <span>+5491144460490</span> */}
            <span>11 5004 8940 </span>
          </div>
        </div>
        <div className={s.redes}>
          <p className={s.follow}>FOLLOW US</p>
          <div className={s.redesContainer}>
            <div>
              <a
                target="_blank"
                // href="https://www.instagram.com/naval_motor/"
                href="https://www.instagram.com/baifa_ar/"
              >
                <BsInstagram className={s.icon} color={"#707070"} />
              </a>
            </div>
            {/* <div>
              <a
                href="https://www.youtube.com/channel/UCT1SJPaMSOsG1_3H0tflf0A"
                target="_blank"
              >
                <BsYoutube size={"2.5em"} color={"#707070"} />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
