import s from "./caracteristicas.module.css"
import precioImg from "../../../assets/descripcion/caracteristicas/reduccion-de-precios.png"
import tortaImg from "../../../assets/descripcion/caracteristicas/grafico-de-torta (2).png"
import citaImg from "../../../assets/descripcion/caracteristicas/cita.png"
import portadaBaifa from "../../../assets/descripcion/caracteristicas/Enmascarar grupo 527.png"
// import portadaBaifaWeb from "../../../assets/descripcion/caracteristicas/baifaWeb.jpg"
import portadaBaifaWeb from "../../../assets/descripcion/caracteristicas/¿Qué es BAIFA.png"
// import motor from "../../../assets/descripcion/caracteristicas/Imagen 432.png"
import motor from "../../../assets/descripcion/caracteristicas/¿Qué es BAUDOUIN.png"
import { pathImages } from "../../../pathImages"
import VideoPlayer from "./videoPlayer/VideoPlayer"
import video from "../../../assets/descripcion/caracteristicas/VideoBaifa.mp4"


export default function Caracteristicas() {
    return (
        <>
            {/* ------------TECNOLOGIA-------------- */}
            <div className={s.section}>
                <div className={s.container}>

                    <div className={s.card}>
                        <div className={s.imgCont}><img src={pathImages +precioImg} alt="precio" /></div>
                        <div className={s.textCont}>
                            <span>Marca y tecnología francesas Weichai, de </span>
                            <span>propiedad exclusiva, económica y confiable.</span>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.imgCont}><img src={pathImages +tortaImg} alt="grafico" /></div>
                        <div className={s.textCont}>
                            <span>Eficiencia energética para</span>
                            <span>cada aplicación</span>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.imgCont}><img src={pathImages +citaImg} alt="calendar" /></div>
                        <div className={s.textCont}>
                            <span>Adaptarse a entornos hostiles, gran</span>
                            <span>gran altitud, frío y calor extremos.</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------QUE ES BAIFA------- */}
            <div className={s.sectionDescripcion} id="Baifa">
                <div className={s.imgCont}>
                    <div className={s.absolu}><span>¿Qué es BAIFA?</span></div>
                    <img className={s.baifaImg} src={pathImages + portadaBaifa} alt="baifa" />
                    <img className={s.baifaImgWeb} src={pathImages + portadaBaifaWeb} alt="baifa" />
                </div>
                <div className={s.paragraphCont}>
                    <div className={s.paraCol}>

                        <p>Ubicada en la ciudad de Wuxi, provincia de Jiangsu, una zona importante de manufactura mundial, Baifa cuenta con bases de producción modernas a gran escala para grupos electrógenos diésel, grupos electrógenos diésel marinos, grupos electrógenos de combustible verde y faros móviles, que cubren un más de 60.000 m2, dentro de los cuales, 34.000 m2 para el taller de montaje. Baifa ha mantenido una cooperación a largo plazo con fabricantes de motores de renombre mundial, como Volvo, CUMMINS, Stamford, Leroy Somer, MTU, Perkins, Mitsubishi, Scania, Baudouin, con unidades de potencia desde 8kW a 3000kW, adecuado para diversos campos de aplicación generales y especiales.</p>
                        <p>El triunfo de Baifa depende de la cultura corporativa central de "integridad y honestidad". Conferimos suma importancia a los detalles en nuestro trabajo y buscamos mejores soluciones de energía con pleno entusiasmo. La demanda de los clientes es nuestra misión.
                        </p>
                    </div>
                    <div className={s.paraCol}>

                        <p>Baifa ha establecido una buena reputación en la industria de grupos electrógenos con su excelente calidad de producto y su atento servicio de preventa y posventa. Sus productos se exportan a más de 115 países y regiones. Para 2021, el volumen de exportación acumulado de Baifa ha alcanzado más de USD700 millones</p>
                        <p>Nuestro objetivo es devenir un conocido fabricante de grupos electrógenos de clase mundial y continuar produciendo productos más confiables, seguros, eficientes y fáciles de usar. En el futuro, atribuiremos más importancia al rendimiento económico de nuestros productos y, al mismo tiempo, seguiremos mejorando la calidad para que nuestros clientes tengan más confianza en nuestros productos y proyectos</p>
                        <p className={s.subTitulo}>Las soluciones integrales de sistemas de generación de energía son nuestras características y ventajas.</p>
                    </div>
                </div>
            </div>
            {/* ------------------VIDEO------------------------- */}
            <div className= {s.videoCont}>
                <h3>Video Corporativo</h3>
                <div className={s.iframe}>
                    {/* <video width={"100%"} src={video59} controls ></video> */}
                    <VideoPlayer video={video} />
                </div>
            </div>
            {/* ----------------------¿Qué Es BAUDOUIN?---------------- */}
            <div className={s.baudouin} id="Baudouin">
                <div className={s.baudouinInfoCont}>
                    <h3>¿Qué es BAUDOUIN?</h3>
                    <p>
                        Baudouin fue fundada en 1903 y durante cien años ha sido el principal proveedor de motores diesel militares franceses y grupos electrógenos diesel. Los productos Baudouin fueron introducidos oficialmente por weichai. ahora, Baudouin se ha convertido en una filial de propiedad total de weichai. baifa es el primer socio OEM de weichai Baudouin en el campo de los grupos electrógenos diesel en china.
                        Baifa trabaja con Baudouin para proporcionar a los clientes soluciones de grupos electrógenos diesel de bajo costo y alta eficiencia. Las unidades de la serie BF - BD son adecuadas para todos los escenarios de aplicación, pueden adaptarse a entornos extremadamente duros y tienen una menor pérdida de potencia del mismo tipo de motor a gran altitud. El tiempo máximo de revisión puede alcanzar las 32.000 horas, lo que garantiza las características de los bajos costos de funcionamiento de la unidad.
                    </p>
                </div>
                <div className={s.imgCont}>
                    <img src={pathImages + motor} alt="motor" />
                </div>
            </div>
        </>
    )
}