import './App.css';
import { Route, Routes, } from 'react-router-dom';
import Landing from './components/Landing/Landing';



function App() {

  return (
    <Routes>
      {/* <Route path="/baifa/" element={<Landing/>}/> */}
      <Route path="/" element={<Landing/>}/>
    </Routes>
  )
}

export default App;
