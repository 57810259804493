import s from "./Navbar.module.css";
import { LuMenu } from "react-icons/lu";
import { IoLogoWhatsapp } from "react-icons/io";
import logo from "../../../assets/navbar/logo-naval.png";
// import baifaLogo from "../../../assets/navbar/Imagen 440.png";
import baifaLogo from "../../../assets/navbar/Imagen 420.png";
import { useState } from "react";
import Hamburger from "./hamburger/Hamburger"
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { pathImages } from "../../../pathImages";

export default function Navbar({ isMobile, landing, setDemo, demo, red, setRed }) {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [scroll, setScroll] = useState(false)


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setScroll(true)
      } else setScroll(false)
    })
  }, [])



  const scrollToSection = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <nav className={s.navbar} style={scroll || demo  || red? { height: "5vh", boxShadow: "0px 2px 4px #25d366" } : {}}>
        <div className={s.navContainer} style={scroll || demo || red ? { gap: "0" } : {}}>
          {/* <div className={s.flex} style={scroll || demo || red ? { display: "none" } : {}}>
            <div className={s.alignMenu}>
              <LuMenu size={"3em"} onClick={() => setMobileMenu(true)} />
            </div>
            <div className={s.imgContainer}>
              <img onClick={() => window.location.href = "https://www.navalmotor.com/"} src={pathImages + logo} alt="logo" />
            </div>
            <div className={s.iconContainer}>
              <IoLogoWhatsapp onClick={() => window.open("https://api.whatsapp.com/send?phone=5491126661777&", '_blank')}
                style={{ color: "#25d366", cursor: "pointer" }}
              />
            </div>
          </div> */}
          <div className={s.categorias}>
            <div className={s.mercuryContainer}>
              <img src={pathImages + baifaLogo} alt="" />
            </div>
            <div className={s.spanCont}>

              <a href = "#Modelos" onClick={() => scrollToSection('Modelos')}
                style={
                  landing && !demo && !red
                    ? {
                      color: "#6EC1E4",
                    }
                    : {}
                }
              >
                Modelos
              </a>
              <a href = "#Baifa" onClick={() => scrollToSection('Baifa')}
                style={
                  demo
                    ? {
                      color: "#6EC1E4",
                    }
                    : {}
                }>
                BAIFA
              </a>


              <a href="#Baudouin" onClick={() => scrollToSection('Baudouin')} style={red ? { color: "#6EC1E4", } : {}}>BAUDOUIN</a>

              {/* <span
                onClick={(e) => window.open("https://www.navalmotor.com/contacto", '_blank')}
              >
                CONTACTO
              </span> */}

            </div>
          </div>
          <Hamburger isMobile={isMobile} setMobileMenu={setMobileMenu} show={mobileMenu} />
        </div>
      </nav>
      <div className={s.navSpace}></div>
      {/* ); */}
    </>
  );
}
