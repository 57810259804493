import React, { Component, useRef, useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from "../../assets/header/Enmascarar grupo 526.png"
import img1 from "../../assets/header/Enmascarar grupo 529.png"
import img2 from "../../assets/header/pexels-mobile.png"
import imgWeb from "../../assets/header/Enmascarar grupo 524.png"
import imgWeb1 from "../../assets/header/Enmascarar grupo 528.png"
import imgWeb2 from "../../assets/header/pexels-marcin-jozwiak-3634730.png"
import s from "./Header.module.css"
import { BsCircleFill } from "react-icons/bs"
import { pathImages } from "../../pathImages";


export default function Header() {
    const sliderRefWeb = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSlideWeb, setCurrentSlideWeb] = useState(0);
    // const cambiarDiapositiva = (nuevaDiapositiva) => {
    //     setValorDiapositiva(nuevaDiapositiva);
    //   };
    

    const settings = {
        infinite: true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        autoplay: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        afterChange: (currentSlide) => {
            setCurrentSlide(currentSlide)
          },
    };

    const settingsWeb = {
        infinite: true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        autoplay: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        value: 3,
        afterChange: (currentSlide) => { 
            setCurrentSlideWeb(currentSlide)
            // console.log(currentSlide, "slide")
          },
    };



    return (
        <div className={s.header}>
            <div className={s.sliderMobile}>
                <Slider  {...settings} ref={sliderRefWeb}>
                    <div className={s.imgCont}>
                        <img src={pathImages + img} />
                    </div>
                    <div className={s.imgCont}>
                        <img src={pathImages + img1} />
                    </div>
                    <div className={s.imgCont}>
                        <img src={pathImages + img2} />
                    </div>

                </Slider>
                <div className={s.circles}>
                    <BsCircleFill style={currentSlide == 0 ? { color: "#67A23A" } : {}} />
                    <BsCircleFill style={currentSlide == 1 ? { color: "#67A23A" } : {}} />
                    <BsCircleFill style={currentSlide == 2 ? { color: "#67A23A" } : {}} />
                </div>
            </div>

            <div className={s.sliderWeb}>
                <Slider  {...settingsWeb} ref={sliderRefWeb}>
                    <div className={s.imgCont}>
                        <img src={pathImages + imgWeb} />
                    </div>
                    <div className={s.imgCont}>
                        <img src={pathImages + imgWeb1} />
                    </div>
                    <div className={s.imgCont}>
                        <img src={pathImages + imgWeb2} />
                    </div>

                </Slider>
                <div className={s.circles}>
                    <BsCircleFill style={currentSlideWeb == 0 ? { color: "#67A23A" } : {}} onClick={()=>{
                        setCurrentSlideWeb(0)
                        sliderRefWeb.current.slickGoTo(0);
                    }}/>
                    <BsCircleFill style={currentSlideWeb == 1 ? { color: "#67A23A" } : {}} onClick={()=>{
                        setCurrentSlideWeb(1)
                        sliderRefWeb.current.slickGoTo(1);
                    }} />
                    <BsCircleFill style={currentSlideWeb == 2 ? { color: "#67A23A" } : {}} onClick={()=>{
                        setCurrentSlideWeb(2)
                        sliderRefWeb.current.slickGoTo(2);
                    }} />
                </div>
            </div>
        </div>
    )
}