import s from "./descripcion.module.css"
// import portada from "../../assets/descripcion/pexels-pixabay-162534.png"
import portada from "../../assets/descripcion/Serie Baifa-BAUDOUIN.png"
import Caracteristicas from "./Caracteristicas/Caracteristicas"
import { pathImages } from "../../pathImages"

export default function Descripcion() {
    return (
        <>
            < div className={s.descripContainer}>
                <div className={s.infoContainer}>
                    <h3>Serie Baifa-BAUDOUIN</h3>
                    <p>Baifa coopera con Baudouin (una subsidiaria de propiedad total de Weichai) para llevar los grupos electrógenos de la serie BF-BD a los clientes y se compromete a brindar a los clientes soluciones de grupos electrógenos diésel con bajos costos de inversión y operación. Baudouin BAUDOUIN se fundó en 1903 y ha sido el principal proveedor de motores diésel y grupos electrógenos militares franceses durante cien años.</p>
                </div>
                <div className={s.imgContainer}>
                    <img src={pathImages + portada} alt="portada" />
                </div>
            </div>
            <Caracteristicas />
        </>
    )
}